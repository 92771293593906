<template>
  <b-table
      :items="getItems"
      :fields="fields"
      :no-border-collapse="true"
      sticky-header
      responsive
      class="g-table mb-0"
      @row-clicked="handleRowClicked"
      show-empty
  >
    <template #head(id)="{ field }">
      <sort-label
          :sort="sort"
          :fieldName="field.key"
          @click.native="handleSortChange(field.key)"
      >
        {{ field.label }}
      </sort-label>
    </template>
    <template #head(date)="{ field }">
      <sort-label
          :sort="sort"
          fieldName="start_date"
          @click.native="handleSortChange('start_date')"
      >
        {{ field.label }}
      </sort-label>
    </template>
    <template #head(employee.name)="{ field }">
      <multiselect
          v-model="filters.employees"
          multiple
          :options="employees"
          :searchable="true"
          :show-labels="false"
          label="name"
          track-by="uid"
          placeholder="Employee"
          class="app-multiselect"
      >
        <template #selection="{ values, search, isOpen }">
          <span
              v-if="values.length"
              v-show="!isOpen"
              class="multiselect__single"
          >
            {{ values?.length }} employee selected
          </span>
        </template>
      </multiselect>
      <i
          v-if="filters.employees.length"
          class="bx bxs-x-circle"
          @click.prevent="filters.employees = []"
      />
    </template>
    <template #head(status)>
      <dropdown-select
          v-model="filters.statuses"
          :options="vacationStatuses"
          valueKey="id"
          label="title"
          class="w-auto"
          multiple
          allOptionsText="All Statuses"
      />
    </template>
    <template #cell(description)="{ item }">
      <span :class="{ 'text-muted': !item.description }">{{ getShortString(item.description, 100) ?? '---' }}</span>
    </template>
    <template #cell(date)="{ item }">
      {{ $moment(item.start_date).format('DD.MM.YYYY') }} - {{ $moment(item.end_date).format('DD.MM.YYYY') }}
    </template>
    <template #cell(vacation_hours)="{ item }">
      {{ item.vacation_hours }}/{{item.penalty}}
    </template>
    <template #cell(status)="{ item }">
      <purchase-status-badge :status="item.status" />
    </template>
    <template #cell(approvers)="{ item }">
      <div class="d-flex" style="gap: 5px;">
        <approver-icon
            v-for="approver in item.approvers"
            :key="approver.id"
            :approver="approver"
        />
      </div>
    </template>
    <template #empty>
      <EmptyTable/>
    </template>
  </b-table>
</template>

<script>
import PurchaseStatusBadge from "@/components/purchases/purchase-status-badge.vue";
import ApproverIcon from "@/components/purchases/approver-icon.vue";
import SortLabel from '@/components/sort-label.vue';
import DropdownSelect from "@/components/dropdown-select.vue";
import EmptyTable from "@/components/empty-table.vue";
export default {
  name: "VacationTable",
  props: {
    fields: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    sort: {
      type: Object
    },
    currentTab: {
      type: Object
    }
  },
  data() {
    return {
      filters: {
        employees: [],
        statuses: [{id: null, title: 'All Statuses', key: ''}]
      }
    }
  },
  watch: {
    filterTableParams(val) {
      this.$emit('tableFilters', val)
    },
    currentTab() {
      this.clearFilterTables()
    }
  },
  computed: {
    filterTableParams() {
      return {
        employee_uid: this.filters.employees.map(el => el.uid),
        status: this.filters.statuses.some(el => el.id)
            ? this.filters.statuses.map(el => el.key ?? null)
            : []
      }
    },
    getItems() {
      return this.items
    },
    employees() {
      return this.$store.getters['vacation/employees']
    },
    vacationStatuses() {
      return this.$store.getters['vacation/vacationStatuses']
    }
  },
  components: {
    DropdownSelect,
    PurchaseStatusBadge,
    ApproverIcon,
    SortLabel,
    EmptyTable
  },
  methods: {
    clearFilterTables() {
      this.filters.employees = []
      this.filters.statuses = [{id: null, title: 'All Statuses', key: ''}]
    },
    handleSortChange(field) {
      this.$emit('changeSort', field)
    },
    getApproveItemByKey(uid, idx, key) {
      return this.items.length
          ? this.items[idx]?.vacation_request_statuses?.find(el => el.employee_uid === uid)?.[key] ?? ''
          : ''
    },
    getShortString(str, limit) {
      if (str && typeof str === 'string') {
        return str.length > limit
            ? str.substr(0, limit) + '...'
            : str
      }
      return null
    },
    handleRowClicked(item) {
      this.$emit('onRowClick', item)
    }
  }
}
</script>
