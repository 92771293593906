<template>
  <b-modal
      v-model="isOpen"
      @show="setData"
      @hidden="clearForm"
      size="md"
      centered
      scrollable
      title="Vacation Request"
      :hide-footer="true"
  >
    <form action="" @submit.prevent="">
      <div class="form-group">
        <label for="">Date Range:</label>
        <date-picker
            :range="true"
            :disabled="!isCanEdit"
            :disabled-date="disabledDate"
            format="DD.MM.YYYY"
            range-separator="-"
            v-model="form.dateRange"
            :class="{ 'is-invalid': [$v.form.dateRange.$anyError].some(e => e) }"
            placeholder="Choose date"
            @change="checkVacationHours"
        />
        <ul v-if="isCanEdit" class="ml-0 mb-0 mt-1 pl-3">
          <li
              class="text-danger"
              v-if="$v.form.dateRange.$dirty && !$v.form.dateRange.requiredField"
          >
            This field is required
          </li>
          <li
              class="text-danger"
              v-if="$v.form.dateRange.$dirty && !$v.form.dateRange.requiredDiffDays"
          >
            Make sure vacation dates do not overlap with previous days off.
          </li>
        </ul>
      </div>
      <div class="d-flex flex-column">
        <div class="d-flex" style="gap: 3px">
          <p class="mb-0 font-weight-bold">Total of vacation hours spent: </p>
          <span>{{ info.totalSpentVacation }}h</span>
        </div>
        <hr class="w-100">
        <p v-if="isCanEdit"><span class="font-weight-bold">Available vacation hours:</span> {{ vacationHours }}h</p>
        <p v-if="info.penaltyHours" class="text-danger">Penalty hours: {{ info.penaltyHours }}h</p>
      </div>
      <div class="form-group">
        <label for="">Description:</label>
        <textarea
            v-if="isCanEdit"
            rows="3"
            v-model="form.description"
            placeholder="Description"
            class="form-control"
        ></textarea>
        <p v-else>
          {{ form?.description ?? '---' }}
        </p>
      </div>
      <div class="d-flex flex-column" v-if="getCurrentApprovers.length">
        <p class="mb-0 font-size-14 font-weight-bold">Status History</p>
        <div v-for="(item, idx) in getCurrentApprovers" :key="idx">
          <hr>
          <div
              class="d-flex align-items-start justify-content-between"
              :class="[item.comment ? 'align-items-start' : 'align-items-center']"
          >
            <div class="d-flex w-75">
              <AvatarStatusIcon
                  :user="item"
                  :status="getStatusFromApprovers(item.status)"
                  class="mr-2"
              />
              <div class="d-flex flex-column" :class="{ 'justify-content-center': !item.comment }">
                <span class="font-weight-bold font-size-14">{{ item.name }}</span>
                <p class="mb-0">{{ item.comment }}</p>
              </div>
            </div>
            <div class="d-flex align-items-start">
              <span class="text-muted">{{ $moment(item.date).format('DD.MM.YYYY HH:mm') }}</span>
            </div>
          </div>
          <hr v-if="(getCurrentApprovers.length - 1) === idx">
        </div>
      </div>
      <div
          v-if="formMode === FORM_MODE.CREATE || formMode === FORM_MODE.EDIT"
          class="d-flex justify-content-end"
      >
        <button
            class="btn btn-primary"
            type="button"
            @click.prevent="handleSubmit">
          {{ formMode === 'edit' ? 'Edit' : 'Save' }}
        </button>
      </div>
      <div
          v-else-if="activeTab?.type === 'all'
          && $hasAccess(['admin', 'hrm'])
          && initialData?.status === 'approved'"
          class="d-flex justify-content-end"
      >
        <button
            class="btn btn-danger d-flex align-items-center"
            @click.prevent="handleCancel"
        >
          <i class='bx bx-minus-circle font-size-14 mr-1'></i>
          Cancel Request
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import AvatarStatusIcon from "@/components/vacation-request/AvatarStatusIcon.vue";
import {FORM_MODE, DEFAULT_EMPLOYEE_SCHEDULE, WORK_HOURS} from "@/utils/constants";

export default {
  name: "VacationFormModal",
  data() {
    return {
      form: {
        dateRange: [],
        description: ''
      },
      info: {
        totalSpentVacation: 0,
        penaltyHours: 0,
      },
      disabledDays: [],
      FORM_MODE
    }
  },
  components: {
    AvatarStatusIcon
  },
  props: {
    value: Boolean,
    initialData: Object,
    formMode: String,
    activeTab: Object
  },
  methods: {
    clearForm() {
      this.$v.form.$reset()
      this.form.dateRange = []
      this.form.description = ''
      this.info.penaltyHours = 0
      this.info.totalSpentVacation = 0
    },
    setData() {
      if (this.initialData) {
        this.form.description = this.initialData.description
        this.info.penaltyHours = this.initialData?.penalty ?? 0
        this.info.totalSpentVacation = this.initialData?.vacation_hours + this.initialData?.penalty
        this.form.dateRange = [
          this.$moment(this.initialData.start_date, 'YYYY-MM-DD').toDate(),
          this.$moment(this.initialData.end_date, 'YYYY-MM-DD').toDate(),
        ]
      } else {
        this.info.vacationHours = this.vacationHours
      }
      this.disabledDays = this.getDisabledDates()
    },
    setDefaultVacationInfo() {
      this.info.totalSpentVacation = 0
      this.info.penaltyHours = 0
    },
    recountVacation(hours) {
      this.setDefaultVacationInfo()
      const res = this.vacationHours - hours
      this.info.totalSpentVacation = hours
      if (res < 0) {
        this.info.penaltyHours = -res
      }
    },
    getStatusFromApprovers(status) {
      if (status === 'approved') {
        return 'success'
      } else if (status === 'declined') {
        return 'danger'
      } else if (status === 'canceled') {
        return 'info'
      } else {
        return ''
      }
    },
    calculateBusinessDay(startDay, endDay) {
      const diffDays = endDay.diff(startDay, 'days') + 1
      const employeeWorkday = this.employeeSchedule.length
          ? [...this.employeeSchedule]
          : [...DEFAULT_EMPLOYEE_SCHEDULE]
      let workDays = 0
      for (let i = 0; i < diffDays; i++) {
        const date = startDay.add(i ? 1 : 0, 'days')
        const dayName = date.format('dddd')
        const currentDateFormat = date.format('YYYY-MM-DD')
        if (employeeWorkday.some(el => el === dayName)
            && !this.currentHolidays.some(el => el.date === currentDateFormat)) {
          workDays += 1
        }
      }
      return workDays
    },
    constructData(data) {
      if (this.formMode === FORM_MODE.EDIT) {
        return {
          id: this.initialData.id,
          data: {
            start_date: this.$moment(data.dateRange[0]).format('YYYY-MM-DD'),
            end_date: this.$moment(data.dateRange[1]).format('YYYY-MM-DD'),
            description: data.description
          }
        }
      }
      return {
        start_date: this.$moment(data.dateRange[0]).format('YYYY-MM-DD'),
        end_date: this.$moment(data.dateRange[1]).format('YYYY-MM-DD'),
        description: data.description
      }
    },
    handleSubmit() {
      if (this.$v.form.$invalid) {
        return this.$v.form.$touch()
      }
      this.$emit('handleSubmit', this.constructData(this.form))
    },
    disabledDate(date) {
      return date < this.$moment().add(-1, 'days').toDate()
          || this.disabledDays.some(el => new Date(el).getTime() === new Date(date).getTime())

    },
    handleCancel() {
      this.$bvModal.msgBoxConfirm(this.createCancelMessageConfirm(), {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then((value) => {
            if (value) {
              this.$emit('handleCancelRequest', this.initialData?.id)
            }
          }).catch(e => e)
    },
    createCancelMessageConfirm() {
      const h = this.$createElement
      const messageVNode = h('div', { class: ['d-flex', 'justify-content-center', 'flex-column'] }, [
        h('span', { class: 'bx bx bx-error text-danger mb-4', style: 'font-size: 70px; text-align: center;' }),
        h('p', { class: ['text-center'], style: 'font-size: 14px;' }, [
          'Are you sure you want to ',
          h('strong', 'Canceled'),
          '? This action ',
          h('strong', 'can`t be undone'),
          ', and the employee will be required to submit a new request.'
        ]),
      ])
      return [messageVNode]
    },
    getFromStartEndDates({startDate, endDate}, format) {
      let results = []
      let sDate = this.$moment(startDate, format)
      let eDate = this.$moment(endDate, format)
      let diffDays = eDate.diff(sDate, 'days')
      for (let i = 0; i <= diffDays; i += 1) {
        results.push(sDate.add(i ? 1 : 0, 'day').toDate())
      }
      return results
    },
    getDisabledDates() {
      let results = []
      this.vacationApprovedRequests.forEach(el => {
        results.push(
            ...this.getFromStartEndDates({
              startDate: el.start_date,
              endDate: el.end_date
            }, 'YYYY-MM-DD'))
      })
      return results
    },
    validateDateRange(dateRange) {
      if (dateRange?.length > 1 && !dateRange.some(el => !el)) {
        let [startDate, endDate] = dateRange
        const dates = this.getFromStartEndDates({startDate, endDate}, '')
        return !dates.some(el => this.disabledDays.some(el2 => new Date(el).getTime() === new Date(el2).getTime()))
      }
      return false
    },
    checkVacationHours() {
      if (this.form.dateRange.length && !this.form.dateRange.some(el => !el)) {
        const startDay = this.$moment(this.form.dateRange[0])
        const endDay = this.$moment(this.form.dateRange[1])
        const workDays = this.calculateBusinessDay(startDay, endDay)
        this.recountVacation(workDays * WORK_HOURS)
      } else {
        this.recountVacation(0)
      }
    }
  },
  computed: {
    getCurrentApprovers() {
      return this.initialData ? this.initialData.approvers.filter(el => el.status) : []
    },
    vacationHours() {
      return this.$store.getters['profile/availableVacationHour']
    },
    currentHolidays() {
      return this.$store.getters['profile/getCurrentHolidays']
    },
    employeeSchedule() {
      return this.$store.getters['profile/getEmployeeSchedule']
    },
    vacationApprovedRequests() {
      return this.$store.getters['vacation/approvedVacationRequests']
    },
    isCanEdit() {
      return this.formMode === FORM_MODE.CREATE || this.formMode === FORM_MODE.EDIT
    },
    isOpen: {
      get() {
        return this.value
      },
      set(val) {
        return this.$emit('input', val)
      }
    }
  },
  validations: {
    form: {
      dateRange: {
        requiredField: function (model) {
          return model.length
              && !model.some(el => !el)
        },
        requiredDiffDays: function (model) {
          return this.validateDateRange(model)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
textarea {
  &:disabled {
    background-color: #f9f9f9;
  }
}

:deep(.mx-datepicker) {
  .mx-input-wrapper {
    input {
      &:disabled {
        background-color: #f9f9f9 !important;
      }
    }
  }
}

</style>
