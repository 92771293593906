<template>
<div class="s-avatar">
  <b-avatar
      variant="info"
      size="30px"
      :title="user.name"
      :text="getNameInitials(user.name)"
      :src="user.photo"
      class="mr-2"
  ></b-avatar>
  <div class="s-avatar__badge" :class="status">
    <i class="bx bx-x" v-if="status === 'danger'"></i>
    <i class="bx bx-check" v-if="status === 'success'"></i>
    <i class="bx bx-minus-circle" v-if="status === 'info'"></i>
  </div>
</div>
</template>

<script>
import { getNameInitials } from '@/utils/user';
export default {
  name: "AvatarStatusIcon",
  methods: {
    getNameInitials
  },
  props: {
    user: Object,
    status: String
  }
}
</script>

<style lang="scss" scoped>
.s-avatar {
  display: flex;
  width: 30px;
  height: 30px;
  position: relative;
  &__badge {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    right: -2px;
    i {
      color: #ffffff;
      font-size: 12px;
    }
    &.success {
      background-color: #28A745;
    }
    &.danger {
      background-color: #DC3545;
    }
    &.info {
      background-color: #6c757d;
    }
  }
}

</style>
