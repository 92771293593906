<template>
  <layout>
    <div class="d-flex align-items-center justify-content-between flex-wrap mb-4">
      <h1 class="title-1">Vacation Requests</h1>
      <div class="d-flex align-items-center">
        <b-button
            class="mr-2"
            variant="warning"
            @click.prevent="handleDownloadFile"
            v-if="tabIndex !== 0"
        >
          <div class="d-flex align-items-center">
            <i class="bx bxs-download font-size-16 mr-2" />
            Export to CSV
          </div>
        </b-button>
        <b-button
            class="mr-2"
            variant="success"
            @click.prevent="handleOpenCreateModal"
        >
          <div class="d-flex align-items-center">
            <i class="bx bx-plus font-size-18 mr-2" />
            Add Vacation Request
          </div>
        </b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-tabs
            nav-class="nav-tabs-custom"
            v-model="tabIndex"
        >
          <b-tab
              @click.prevent="handleOpenTab('my_request')"
          >
            <template #title>
              <span class="font-size-16">
                My Requests
              </span>
            </template>
          </b-tab>
          <b-tab
              :title-item-class="getTabClass('waiting_for_approval')"
              @click.prevent="handleOpenTab('waiting_for_approval')"
          >
            <template #title>
              <span class="font-size-16">
                Waiting For Approval
              </span>
              <span
                  class="nav-link__badge"
              >
                {{ getTabCountByKey('waiting_for_approval') }}
              </span>
            </template>
          </b-tab>
          <b-tab
              :title-item-class="getTabClass('all')"
              @click.prevent="handleOpenTab('all')"
          >
            <template #title>
              <span class="font-size-16">
                All Requests
              </span>
            </template>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-3" v-load="loading">
        <b-card
            body-class="p-0"
            class="mb-0"
        >
         <VacationTable
             :fields="getFields"
             :items="items"
             :style="{
               minHeight: total > 10 ? 'calc(100vh - 200px)' : 'calc(100vh - 170px)',
               maxHeight: total > 10 ? 'calc(100vh - 200px)' : 'calc(100vh - 170px)'
             }"
             :sort="sort"
             :current-tab="activeTabItem"
             @onRowClick="handleOpenModal"
             @tableFilters="updateTableFilters"
             @changeSort="handleChangeSortTable"
         />
        </b-card>
      </div>
      <div class="col-12">
        <pagination
            :page="pagination.page"
            :limit="pagination.limit"
            :totalRecordsCount="total"
            @onPageChange="handlePageChange"
            @onLimitChange="handleLimitChange"
        />
      </div>
    </div>
    <VacationFormModal
        v-model="modal.vacationModal"
        :initialData="modal.currentRow"
        :formMode="modal.formMode"
        :activeTab="activeTabItem"
        @handleSubmit="handleSubmit"
        @handleCancelRequest="handleCancelRequest"
    />
    <VacationApproveModal
        v-model="modal.vacationApproveModal"
        :initialData="modal.currentRow"
        :formMode="modal.formMode"
        @changeStatus="handleChangeStatus"
    />
  </layout>
</template>

<script>
import { FORM_MODE } from "@/utils/constants";
import VacationFormModal from "@/components/vacation-request/VacationFormModal.vue";
import VacationApproveModal from "@/components/vacation-request/VacationApproveModal.vue";
import VacationTable from "@/components/vacation-request/VacationTable.vue";
import Pagination from "@/components/pagination.vue";
import {mapActions} from "vuex";
export default {
  name: "index",
  data() {
    return {
      activeTabItem: null,
      tabIndex: 0,
      fields: [
        {
          key: 'id',
          label: '№',
          thStyle: { width: '50px' },
          tabIds: [1, 2, 3]
        },
        {
          key: 'employee.name',
          label: 'Employee',
          thStyle: { width: '200px' },
          tabIds: [2, 3]
        },
        {
          key: 'date',
          label: 'Date',
          thStyle: { width: '200px' },
          tabIds: [1, 2, 3]
        },
        {
          key: 'description',
          label: 'Description',
          thStyle: { minWidth: '150px' },
          tabIds: [1, 2, 3]
        },
        {
          key: 'vacation_hours',
          label: 'Vacation Hours/Penalty',
          thStyle: { width: '200px', minWidth: '200px' },
          tabIds: [1, 2, 3]
        },
        {
          key: 'status',
          label: 'Status',
          thStyle: { width: '150px', minWidth: '150px' },
          thClass: 'z-index-5',
          tabIds: [1, 2, 3]
        },
        {
          key: 'approvers',
          label: 'Approvers',
          thStyle: { width: '120px' },
          tabIds: [1, 2, 3]
        }
      ],
      tabs: [
        {
          id: 1,
          title: 'My Requests',
          type: 'my_request',
          modalName: 'vacationModal',
        },
        {
          id: 2,
          title: 'Waiting For Approval',
          type: 'waiting_for_approval',
          modalName: 'vacationApproveModal',
        },
        {
          id: 3,
          title: 'All Requests',
          type: 'all',
          modalName: 'vacationModal',
        }
      ],
      modal: {
        vacationModal: false,
        vacationApproveModal: false,
        currentRow: null,
        formMode: null,
      },
      tableFilters: {},
      sort: {
        field: 'start_date',
        direction: 'desc',
      },
      pagination: {
        page: 1,
        limit: 20
      },
      FORM_MODE
    }
  },
  components: {
    VacationFormModal,
    VacationApproveModal,
    VacationTable,
    Pagination
  },
  methods: {
    ...mapActions('vacation', {
      index: 'getVacations',
      createVacation: 'createVacation',
      editVacation: 'updateVacation',
      changeStatus: 'changeVacationStatus',
      cancelStatus: 'cancelVacationStatus'
    }),
    handleOpenCreateModal() {
      this.modal.currentRow = null
      this.modal.formMode = FORM_MODE.CREATE
      this.modal.vacationModal = true
    },
    async handleOpenTab(type) {
      if (this.activeTabItem?.type !== type) {
        const activeTabItem = this.tabs.find(el => el.type === type)
        this.pagination.page = 1
        this.tableFilters = {}
        this.activeTabItem = activeTabItem ? activeTabItem : this.tabs[0]
      }
    },
    checkTab(key) {
      return key === 'my_request' ? true : !!this.vacationTabs?.[key]
    },
    getTabCountByKey(key) {
      return this.vacationTabs?.[key] ?? 0
    },
    getFormMode(item) {
      if (this.activeTabItem?.type === 'my_request') {
        return item?.vacation_request_statuses?.some(el => el)
            ? FORM_MODE.SHOW
            : FORM_MODE.EDIT
      } else if (this.activeTabItem?.type === 'all') {
        return FORM_MODE.SHOW
      } else {
        return FORM_MODE.EDIT
      }
    },
    handleOpenModal(row = null) {
      this.modal.formMode = this.getFormMode(row)
      this.modal.currentRow = row
      this.modal[this.activeTabItem.modalName] = true
    },
    async handleSubmit(data) {
      await this[`${this.modal.formMode}Vacation`](data)
      await this.index(this.queryParams)
      this.modal.vacationModal = false
    },
    async handleCancelRequest(id) {
      await this.cancelStatus(id)
      await this.index(this.queryParams)
      this.modal.vacationModal = false
    },
    getApproveItemByKey(uid, idx, key) {
      return this.vacationItems.length
          ? this.vacationItems[idx]?.vacation_request_statuses?.find(el => el.employee_uid === uid)?.[key] ?? ''
          : ''
    },
    getTabClass(key) {
      return this.vacationTabs?.[key] ? '' : 'd-none';
    },
    getApprovers(item, idx) {
      const isFromWaiting = item?.status === 'waiting_for_approval'
      const list = isFromWaiting
          ? item?.approvers ?? []
          : item?.vacation_request_statuses ?? []

      return list.map(el => {
        let employee = isFromWaiting ? { ...el } : { ...el.employee }
        return {
          ...employee,
          comment: isFromWaiting
              ? this.getApproveItemByKey(el.uid, idx, 'comment')
              : el.comment,
          status: isFromWaiting
              ? this.getApproveItemByKey(el.uid, idx, 'status')
              : el.status,
          date: isFromWaiting
              ? this.getApproveItemByKey(el.uid, idx, 'created_at')
              : el.created_at
        }
      })
    },
    async updateTableFilters(params) {
      const checkUrlParamsWithTab = this.checkUrlParams()
          && this.tabs.find(el => el.type === this.$route.query?.type)
      this.tableFilters = {...params}
      await this.index({
        ...this.queryParams,
        per_page: checkUrlParamsWithTab
            ? 100
            : this.pagination.limit
      })
      if (this.checkUrlParams()) {
        const {type, id} = this.$route.query
        const row = this.items.find(el => +el.id === +id)
        if (row) {
          this.tabIndex = this.tabs.filter(el => this.checkTab(el.type)).findIndex(el => el.type === type)
          this.handleOpenModal(row)
        } else {
          await this.handleOpenTab('my_request')
        }
        this.$router.replace({ name: 'vacation-requests' })
      }
    },
    async handleChangeSortTable(field){
      if (this.sort.field === field) {
        if (this.sort.direction === 'asc') {
          this.sort.direction = 'desc';
        } else if (this.sort.direction === 'desc') {
          this.sort.field = '';
          this.sort.direction = '';
        }
      } else {
        this.sort.field = field;
        this.sort.direction = 'asc';
      }
      await this.index(this.queryParams)
    },
    async handleChangeStatus(data) {
      const tabs = {...this.vacationTabs}
      await this.changeStatus(data)
      if (tabs?.['waiting_for_approval'] === 1) {
        this.modal[this.activeTabItem.modalName] = false
        this.tabIndex = 0
        await this.handleOpenTab('my_request')
      } else {
        this.modal[this.activeTabItem.modalName] = false
        await this.index(this.queryParams)
      }
      this.$store.dispatch('notifications/getNotificationChecksAndCounts')
    },
    async handlePageChange(event) {
      this.pagination.page = event
      await this.index(this.queryParams)
    },
    async handleLimitChange(event) {
      this.pagination.limit = event
      await this.index(this.queryParams)
    },
    getVacationTypeParams() {
      if (this.activeTabItem.type === 'my_request') {
        return null
      }
      return this.activeTabItem?.type ?? null
    },
    async handleDownloadFile() {
      await this.$store.dispatch('vacation/downloadFile', this.queryParams)
    },
    checkUrlParams() {
      const { id, type } = this.$route.query
      return id && type
    },
  },
  computed: {
    loading() {
      return this.$store.state.vacation.loading
    },
    getFields() {
      return this.fields.filter(el => el.tabIds.some(id => id === this.activeTabItem?.id))
    },
    vacationTabs() {
      return this.$store.state.vacation.tabs
    },
    requestSortParam() {
      return this.sort.field
          ? { [`sort[${this.sort.field}]`]: this.sort.direction }
          : {};
    },
    queryParams() {
      return {
        type: this.getVacationTypeParams(),
        page: this.pagination.page,
        per_page: this.pagination.limit,
        ...this.requestSortParam,
        ...this.tableFilters
      }
    },
    items() {
      return this.vacationItems.map((el, idx) => {
        return {
          ...el,
          approvers: this.getApprovers(el, idx)
        }
      })
    },
    vacationItems() {
      return this.$store.getters['vacation/vacationItems']
    },
    total() {
      return this.$store.getters['vacation/totalVacationItems']
    }
  },
  async mounted() {
    await this.handleOpenTab(
        `${this.checkUrlParams()
            ? this.$route.query.type
            : 'my_request'}`
    )
  }
}
</script>
