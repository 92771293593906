<template>
  <b-modal
      v-model="isOpen"
      @show="setData"
      size="md"
      centered
      scrollable
      :title="`Vacation Request from ${this.initialData?.employee?.name ?? '---'}`"
  >
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="">Start Date:</label>
          <p>{{ form.startDate }}</p>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="">End Date:</label>
          <p>{{ form.endDate }}</p>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="">Vacation Hours Spent:</label>
          <p>{{ initialData?.vacation_hours ?? 0 }}h</p>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="">Penalty:</label>
          <p :class="{'text-danger': initialData?.penalty > 0}">{{ initialData?.penalty ?? 0 }}h</p>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="">Description:</label>
          <p>{{ form?.description ?? '---' }}</p>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <textarea rows="3" v-model="form.comment" placeholder="Comment" class="form-control"></textarea>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <div
          class="d-flex justify-content-between w-100"
          v-if="formMode === FORM_MODE.EDIT"
      >
        <button class="btn btn-danger" @click.prevent="handleChangeStatus('declined')">Decline</button>
        <button class="btn btn-success" @click.prevent="handleChangeStatus('approved')">Approve</button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {FORM_MODE} from "@/utils/constants";

export default {
  name: "VacationApproveModal",
  props: {
    value: Boolean,
    initialData: Object,
    formMode: String
  },
  data() {
    return {
      form: {
        startDate: null,
        endDate: null,
        description: '',
        comment: ''
      },
      FORM_MODE
    }
  },
  methods: {
    setData() {
      if (this.initialData) {
        this.form.description = this.initialData.description
        this.form.startDate = this.$moment(this.initialData.start_date, 'YYYY-MM-DD').format('DD.MM.YYYY')
        this.form.endDate = this.$moment(this.initialData.end_date, 'YYYY-MM-DD').format('DD.MM.YYYY')
      }
      this.form.comment = ''
    },
    handleChangeStatus(status) {
      if (status === 'declined') {
        this.$bvModal.msgBoxConfirm(this.createDeclineMessageConfirm(), {
          title: 'Please Confirm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
            .then((value) => {
              if (value) {
                this.$emit('changeStatus', {
                  vacation_request_id: this.initialData.id,
                  comment: this.form.comment,
                  status
                })
              }
            }).catch(e => e)
      } else {
        this.$emit('changeStatus', {
          vacation_request_id: this.initialData.id,
          comment: this.form.comment,
          status
        })
      }
    },
    createDeclineMessageConfirm() {
      const h = this.$createElement
      const messageVNode = h('div', { class: ['d-flex', 'justify-content-center', 'flex-column'] }, [
        h('span', { class: 'bx bx bx-error text-danger mb-4', style: 'font-size: 70px; text-align: center;' }),
        h('p', { class: ['text-center'], style: 'font-size: 14px;' }, [
          'Are you sure you want to ',
          h('strong', 'Decline'),
          '? This action ',
          h('strong', 'can`t be undone'),
          ', and the employee will be required to submit a new request.'
        ]),
      ])
      return [messageVNode]
    },
  },
  computed: {
    isOpen: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped>

</style>
