<script>
export default {
  name: 'empty-table-component'
}
</script>

<template>
<div class="empty-table">
  <h4>No Data!</h4>
  <img src="@/assets/images/empty.png" alt="">
</div>
</template>

<style scoped lang="scss">
.empty-table {
  h4 {
    text-align: center;
    font-size: 20px;
  }
  img {
    width: 300px;
  }
  position: absolute;
  top: calc(50% - 150px);
  left: calc(50% - 150px);
}
@media screen and (max-width: 575px) {
  .empty-table {
    top: calc(50% - 125px);
    left: calc(50% - 125px);
    h4 {
      font-size: 18px;
    }
    img {
      width: 250px;
    }
  }
}
@media screen and (max-height: 575px) {
  .empty-table {
    top: 50%;
    left: calc(50% - 41px);
    img {
      display: none;
    }
  }
}
</style>
